<template>
  <div>
    <v-card flat>
      <v-expand-transition>
        <BudgetFilter v-show="showFilters" @clearFilterEvent="onClearFilterEvent"></BudgetFilter>
      </v-expand-transition>

      <div v-if="viewFiltered">
        <v-data-table :headers="headers" :items="customerCollection" :single-expand="singleExpand" :expanded.sync="expanded" :sort-by="['sumBudget']" :sort-desc="[true]" item-key="customerId" show-expand class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat class="p">
              <v-toolbar-title v-t="'budget'"></v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="success" dark class="mb-2 mx-1" @click="switchView()">
                <v-icon>mdi-swap-horizontal</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mb-2 mx-1" color="primary" dark v-bind="attrs" v-on="on">
                    <v-icon>mdi-cash-plus</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5" style="display: block;">
                    {{$t('queryApplyBudget')}}
                    <v-spacer></v-spacer>
                    <v-icon style="float: right;" @click="dialog = !dialog">mdi-close</v-icon>
                  </v-card-title>
                  <v-card-text>
                    <p class="mt-3 mb-5">{{$t('applyChangeTo')}}</p>
                    <v-row class="mt-3">
                      <v-col cols="12" sm="12">
                        <v-select dense v-model="newBudget.tag" :items="getOrderStatisticalTagCollection" :label="$t('statisticalTag')" outlined></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" v-if="newBudget.tag >= 0">
                        <v-text-field v-model="newBudget.value" outlined clearable dense hide-details="auto" :label="$t('newParam', {param:$t('budget')})" autocomplete="nope" @keyup.enter="showNewBudgetModal"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="applyBudget(true)">
                      {{$t('applyToParam', {param: $t("all")})}}
                    </v-btn>
                    <v-divider vertical class="mx-5"></v-divider>
                    <v-btn color="green darken-1" text @click="applyBudget(false)">
                      {{$t('applyToParam', {param: $t("missing")})}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn color="info" dark class="mb-2 mx-1" @click="showFilters = !showFilters">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 85%, rgba(224,224,224,1) 91%, rgba(186,186,186,1) 96%, rgba(134,134,134,1) 100%);">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-card class="mx-auto my-8">
                    <v-card-title>
                      {{ $t('budget') }}
                    </v-card-title>
                    <v-card-subtitle>
                      {{ $t('descriptionBudgetAvailability') }}
                    </v-card-subtitle>
                    <v-card-text>
                      <p>{{ $t('descriptionModifyBudget') }}</p>
                      <p>
                        {{ $t('descriptionLargeScaleBudgetInput') }}          
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-timeline dense clipped align-top class="mb-10">
                    <v-timeline-item fill-dot left v-for="(bo, i) in item.budgetObjectives" :key="i">
                      <v-card color="blue-grey" dark>
                        <v-card-title class="text-h6">
                          <b>{{ bo.orderStatisticalTagDescr }}</b>
                          <v-spacer></v-spacer>
                          <div style="display: flex; vertical-align:middle;">
                            <v-text-field append-outer-icon="mdi-arrow-right" hide-details outlined v-if="isSelected(bo, i)" v-model="tempValue[`${bo.id}${i}`]" dense @keyup.enter="applyBudgetUpdate(bo, i)" @click:append-outer="applyBudgetUpdate(bo, i)"></v-text-field>
                            <b v-else class="mr-3" style="font-weight: 600; font-size: larger;">{{ bo.budgetValueObjective | currency }}</b>
                          </div>
                          <v-divider vertical class="mx-3"></v-divider>
                          <v-icon @click="switchProp(bo, i)">mdi-pencil-outline</v-icon>
                          <v-divider vertical class="mx-3"></v-divider>
                          <v-icon @click="deleteBudget(bo)">mdi-trash-can-outline</v-icon>
                        </v-card-title>
                      </v-card>
                    </v-timeline-item>
                    <v-timeline-item fill-dot icon="mdi-plus" left>
                      <v-card color="blue-grey" dark>
                        <v-card-title class="text-h6">
                          <v-select hide-details dense v-model="newBudget.tag" :items="getOrderStatisticalTagCollectionFiltered(item.budgetObjectives)" outlined></v-select>
                          <v-text-field class="ml-2" :placeholder="$t('budget')" append-outer-icon="mdi-arrow-right" hide-details outlined v-model="tempValue[`new`]" dense @keyup.enter="applyBudgetUpdate(null, 'new', item)" @click:append-outer="applyBudgetUpdate(null, 'new', item)"></v-text-field>
                        </v-card-title>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
            </td>
          </template>
          <template v-slot:item.customerCode="{ item }">
            <div @click="redirectTo(item)" class="pointer" style="font-weight: 500;">
              {{ `${item.customerCode} - ${item.customerBusinessName} ${!!item.distriChildrenNr? `[${item.distriChildrenNr} children]` : ``}` }}
            </div>
          </template>
          <template v-slot:item.sumBudget="{ item }">
            {{ item.sumBudget | tenantCurrency }}
          </template>
          <template v-slot:item.budgetObjectives="{ item }">
            {{ gedReducedValues(item.budgetObjectives) | currency }}
          </template>
          <template v-slot:no-data>
            <v-btn text color="primary">
              {{$t('noData')}}
            </v-btn>
          </template>
          <template v-slot:body.append>
            <td colspan="6">
              <v-row>
                <v-col class="mt-5 mb-3" cols="12" sm="12" style="text-align: end;">
                  <p>{{$t('customers')}} : {{ customersDataReduce.customers }}</p>
                  <p>{{$t('total')}} : {{ customersDataReduce.total | tenantCurrency }}</p>
                  <p>{{$t('ourChampion')}} {{ customersDataReduce.maxName }} {{ $t('with') }} {{ customersDataReduce.max | tenantCurrency }}</p>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <v-data-table
          :headers="headersResume" 
          :items="countryCollection" 
          :items-per-page="10" 
          :sort-by="['budgetTotal']"
          :sort-desc="[true]"
          class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat class="p">
              <v-toolbar-title v-t="'budget'"></v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="success" dark class="mb-2 mx-1" @click="switchView()">
                <v-icon>mdi-swap-horizontal</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogGlobe" width="1200">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on"><v-icon>mdi-earth</v-icon></v-btn>
                </template>
                <v-card>
                  <v-card-text style="position:relative;">
                    <div style="z-index:999; width:95%; height:95%; position:absolute; background-color:transparent; padding:0%; margin:0%;">
                      <p><span><v-icon color="red">mdi-circle</v-icon></span> - {{$t('labelWithoutBudget')}}</p>
                      <p><span><v-icon>mdi-circle-outline</v-icon></span> - {{$t('labelLowBudget')}}</p>
                      <p><span><v-icon color="green">mdi-circle</v-icon></span> - {{$t('labelHighBudget')}}</p>
                    </div>
                    <MapChart
                      :countryData="reducedCountries"
                      highColor="#00ff00"
                      lowColor="#ffffff"
                      countryStrokeColor="#adadad"
                      defaultCountryFillColor="#ffaa99"
                    />  
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogGlobe = false">{{$t('close')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.country="{ item }">
            <div style="font-weight: 500; cursor: pointer;">
              <span @click="setFilterAndSwitch(item.country)">
                {{ country(item.country) }}
              </span>
            </div>
          </template>
          <template v-slot:item.budgetTotal="{ item }">
            <div style="font-weight: 500;">
              {{ item.budgetTotal | tenantCurrency }}
            </div>
          </template>
          <template v-slot:no-data>
            <v-btn text color="primary">
              {{$t('noData')}}
            </v-btn>
          </template>
          <template v-slot:body.append>
            <td colspan="2">
              <v-row style="width: 100%;">
                <v-col class="mt-5 mb-3" cols="12" sm="12" style="text-align: end;">
                  <p class="my-5 mr-5">{{$t('labelGlobalBudget')}} : {{ countriesDataReduce.total | tenantCurrency }}</p>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>


<script>
import {call, get, sync} from "vuex-pathify";
import BudgetFilter from "@/components/filters/BudgetFilter";
import MapChart from 'vue-map-chart'
export default {
  components: {
    BudgetFilter,
    MapChart
  },
  data: () => ({
    tempValue: {},
    dialogGlobe: false,
    tempSelected: '',
    dialog: false,
    showFilters: false,
    show: false,
    emptyColl: [],
    expanded: [],
    singleExpand: true,
    newBudget: {
      tag: -1,
      value: 0
    },
    reducedCountries: {}
  }),
  computed: {
    headers() {
      return [
        { text: this.$t('customer'), value: 'customerCode' },
        { text: this.$t('budget'), value: 'sumBudget', align: 'end' },
        { text: this.$t('country', {r:''}), value: 'country' },
        { text: this.$t('sector'), value: 'salesSector' },
        { text: this.$t('channel'), value: 'salesChannel' },
        { text: this.$t('purchaseCategory'), value: 'purchaseCategory' },
        { text: '', value: 'data-table-expand' },
      ]
    },
    headersResume() {
      return [
        { text: this.$t('country', {r:''}), value: 'country' },
        { text: this.$t('budget'), value: 'budgetTotal' },
      ];
    },
    customerCollection: sync("customerBudget/customerCollection"),
    countryCollection: sync("customerBudget/countryCollection"),
    filters: sync("customerBudget/filters"),
    orderStatisticalTags: get("customerMonitoring/orderStatisticalTags"),
    orderStatisticalTagCollection: get("statistics/orderStatisticalTagDropdown"),
    viewFiltered: get("customerBudget/viewFiltered"),
    country: get("geoname/country"),
    getOrderStatisticalTagCollection: function() {
      return [{text: this.$t('all'), value: -1}, ...this.orderStatisticalTagCollection]
    },
    customersDataReduce: function() {
      return this.customerCollection?.reduce((a, b) => {
        let total = b.budgetObjectives.reduce((i, j) => {
          i = i ?? 0;
          i += j.budgetValueObjective ?? 0;
          return i;
        }, 0);
        a.total += total;
        a.customers += 1;
        a.maxName = a.max < total? `${b.customerCode} - ${b.customerBusinessName}` : a.maxName;
        a.max = a.max < total? total : a.max;
        return a;
      }, {
        total: 0,
        customers: 0,
        max: 0,
        maxName: ''
      })
    },
    countriesDataReduce: function() {
      return this.countryCollection?.reduce((a, b) => {
        a.total += +b.budgetTotal ?? 0;
        return a;
      }, {
        total: 0
      })
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.initCustomerCollection();
      this.apiOrderStatisticalTagGet();
      this.apiCustomerBudgetObjectivesFilteredCountryDetailsPost()
      .then((res) => {
        this.reducedCountries = res.reduce((a, b) => {
          a[b.country] = +b.budgetTotal;
          return a;
        }, {})
      });
    },
    setFilterAndSwitch(param) {
      this.switchView();
      this.filters.geonameCountryCode = this.filters.geonameCountryCode ?? [];
      this.filters.geonameCountryCode.length = 0;
      this.filters.geonameCountryCode.push(param);
      this.apiCustomerBudgetObjectivesFilteredPost();
    },
    onClearFilterEvent() {
      this.showFilters = false;
    },
    redirectTo(item) {
      this.$router.push('customers/' + item.customerId);
    },
    applyBudgetUpdate(bo, i, item) {
      if (!!bo) {
        this.apiCustomerBudgetObjectivesAdjustCustomerObjectivePut({...bo, budgetValueObjective: +this.tempValue[`${bo.id}${i}`]})
        .finally(() => {
          this.tempSelected = null;
          delete this.tempValue[`${bo.id}${i}`]; 
        })
      } else {
        if(this.newBudget.tag < 1) {
          this.showError(this.$t('selectValidStatisticalTag'));
          return;
        }
        if(this.tempValue['new'] <= 0 || this.tempValue['new'] != +this.tempValue['new']) {
          this.showError(this.$t('insertValidParam', {param: this.$t('budget')}));
          return;
        }
        this.apiCustomerBudgetObjectivesAdjustCustomerObjectivePut({
          customerId: item.customerId,
          orderStatisticalTagId: this.newBudget.tag,
          budgetValueObjective: this.tempValue['new']
        })
        .finally(() => {
          this.newBudget.tag = -1;
          this.tempSelected = null;
          delete this.tempValue['new']; 
        })
      }
    },
    getOrderStatisticalTagCollectionFiltered(budgetObjectives){
      return this.orderStatisticalTagCollection.filter(tag => !budgetObjectives?.find(x => x.orderStatisticalTagId == tag.value))
    },
    switchProp(bo, i) {
      this.tempSelected = this.tempSelected == `${bo.id}${i}`
        ? ''
        : `${bo.id}${i}`;
      this.tempValue[`${bo.id}${i}`] = bo.budgetValueObjective;
    },
    isSelected(item, i) {
      return this.tempSelected == `${item.id}${i}`;
    },
    gedReducedValues(collection) {
      return collection.reduce((a, b) => {
        a = a ?? 0;
        a += b.budgetValueObjective ?? 0;
        return a;
      }, 0)
    },
    showNewBudgetModal(){
      if(this.newBudget.value > 0) {
        this.dialog = !this.dialog;
      } 
    },
    deleteBudget(i){
      this.showConfirm({
        message: this.$t('deleteParam', {param: this.$t('budget')}),
        action: this.apiCustomerBudgetObjectivesIdDelete,
        params: i.id,
        callback: () => {
          //dindu
        }
      });
    },
    applyBudget(applytoAll = false){
      let filteredCollection = applytoAll
        ? null 
        : this.customerCollection.filter(el => !el.budgetObjectives.find(x => x.orderStatisticalTagId == this.newBudget.tag));
      if(!(filteredCollection ?? this.customerCollection).length) {
        this.showWarning(this.$t('applyBudgetError'));
        return;
      }
      this.showConfirm({
        message: `${this.$t('applyParam', this.$t('budget'))} [${this.newBudget.value}] ${this.$t('to')} [${(filteredCollection ?? this.customerCollection).length}] ${this.$t('customers')}?`,
        action: this.apiCustomerCollectionBudgetObjectivesPut,
        params: {
          customerCollection: (filteredCollection ?? this.customerCollection),
          orderStatisticalTagId: this.newBudget.tag,
          budgetValueObjective: this.newBudget.value
        },
        callback: () => {
          this.apiCustomerBudgetObjectivesFilteredPost();
          this.dialog = false;
        }
      })
    },
    ...call("customerMonitoring/*"),
    ...call("customerBudget/*"),
    ...call("customers/*"),
    ...call("statistics/*"),
    ...call("app/*"),
  },
};
</script>
